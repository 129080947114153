import { useEffect, useState } from "react";
import Error404 from "../../pages/Error404";

function DelayedError404() {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowError(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return showError ? <Error404 /> : null;
}
export default DelayedError404;
