import React, { useState } from "react";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";

function PaginationComp({
  size,
  gutter,
  variant,
  bg,
  circle,
  currentpage,
  setCurrentPage,
}) {
  //   const [currentpage, setCurrentPage] = useState(1);
  const maxDisplay = 1; // Minimum number of page buttons to display

  // Calculate the start and end pages based on the active page
  let startPage = currentpage - Math.floor(maxDisplay / 2);
  startPage = Math.max(startPage, 1); // Ensure startPage is at least 1

  let endPage = startPage + maxDisplay - 1;
  if (endPage > size) {
    // If endPage exceeds the total size, shift the range back
    endPage = size;
    startPage = Math.max(1, endPage - maxDisplay + 1);
  }

  // Generate the pagination items
  const items = [];
  for (let number = startPage; number <= endPage; number++) {
    items.push(
      <Pagination.Item
        key={number}
        onClick={() => setCurrentPage(number)}
        active={number === currentpage}
      >
        {number}
      </Pagination.Item>
    );
  }

  const handleNextButtonAndPrevButton = (direction) => {
    if (direction === "next" && currentpage < size) {
      setCurrentPage(currentpage + 1);
    } else if (direction === "previous" && currentpage > 1) {
      setCurrentPage(currentpage - 1);
    }
  };

  return (
    <Pagination
      size={size}
      className={`mt-4 ${gutter ? "pagination-gutter" : ""} ${
        variant && `pagination-${variant}`
      } ${!bg && "no-bg"} ${circle && "pagination-circle"}`}
    >
      {/* Previous button */}
      <li className="page-item page-indicator">
        <Link className="page-link" to="#">
          <i
            className="la la-angle-left"
            onClick={() => handleNextButtonAndPrevButton("previous")}
          />
        </Link>
      </li>

      {/* Render page items */}
      {items}

      {/* Next button */}
      <li className="page-item page-indicator">
        <Link className="page-link" to="#">
          <i
            className="la la-angle-right"
            onClick={() => handleNextButtonAndPrevButton("next")}
          />
        </Link>
      </li>
    </Pagination>
  );
}

export default PaginationComp;
