export const SuperAdminMenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <i className="fas fa-home"></i>,
  },
  {
    title: "Client",
    classsChange: "mm-collapse",
    // update:"New",
    iconStyle: <i className="fas fa-users" />,
    content: [
      {
        title: "Client List",
        to: "super-admin-client",
      },
    ],
  },

  {
    title: "users Category",
    classsChange: "mm-collapse",
    // update:"New",
    iconStyle: <i className="fas fa-users" />,
    content: [
      {
        title: "User Category Type",
        to: "user-category-type",
      },
      {
        title: "User Sub Category",
        to: "user-sub-category",
      },
    ],
  },
];

export const AdminMenuList = [
  // {
  //   title: "Manage Registration",
  //   classsChange: "mm-collapse",
  //   // update:"New",
  //   iconStyle: <i className="fas fa-users" />,
  //   content: [
  //     {
  //       title: "Institution List",
  //       to: "Organization",
  //     },
  //     {
  //       title: "Individual  List",
  //       to: "Individual",
  //     },
  //   ],
  // },

  // {
  //   title: "Manage Verification",
  //   classsChange: "mm-collapse",
  //   // update:"New",
  //   iconStyle: <i className="fas fa-suitcase" />,
  //   content: [
  //     {
  //       title: "Institution  Verification List",
  //       to: "verified-insitute-user",
  //     },
  //     {
  //       title: "Individual Verification List",
  //       to: "verified-individual-user",
  //     },
  //   ],
  // },
  // {
  //   title: "Notification Management",
  //   to: "communication",
  //   iconStyle: <i class="bi bi-bell-fill"></i>,
  //   content: [
  //     {
  //       title: "Communication",
  //       to: "communication/communication-list",
  //     },
  //     {
  //       title: "Website Notification",
  //       to: "communication/website-notification-list",
  //     },
  //   ],
  // },
  // {
  //   title: "Event Management",
  //   to: "event/event-calendar",
  //   iconStyle: <i class="bi bi-calendar-event-fill"></i>,
  //   content: [
  //     {
  //       title: "Event",
  //       to: "event/event-calendar",
  //     },
  //   ],
  // },
  {
    title: "Grievance Management",
    to: "communication",
    iconStyle: <i class="bi bi-chat-fill"></i>,
    content: [
      {
        title: "Grievance Management",
        to: "grievance-management",
      },
    ],
  },
];

export const UserMenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <i className="fas fa-home"></i>,
  },
  {
    title: "Grievance Management",
    classsChange: "mm-collapse",
    iconStyle: <i class="bi bi-ticket-perforated-fill"></i>,
    content: [
      {
        title: "Tickets",
        to: "user-grievance-management",
      },
    ],
  },
];
