// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const fetchLoginDetails = () => {
    const loginDetails = JSON.parse(localStorage.getItem("login_Details"));

    if (loginDetails?.token) {
      setUser({
        role: `${loginDetails?.user_level}`, // Example role, fetch this dynamically
      });
    }
  };
  useEffect(() => {
    fetchLoginDetails(); // Initial fetch

    // Listen for custom login event
    window.addEventListener("login", fetchLoginDetails);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("login", fetchLoginDetails);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, fetchLoginDetails }}>
      {children}
    </AuthContext.Provider>
  );
};
