/// Menu
//import Metismenu from "metismenujs";
import React, { useContext, useReducer, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

//import { Dropdown } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
//import LogoutPage from './Logout';

/// Image

import { UserMenuList } from "./Menu";
import { SuperAdminMenuList, AdminMenuList } from "./Menu";
import SuperAdminSidebar from "./SuperAdminSidebar";
import AdminSidebar from "./AdminSidebar";
import { AuthContext } from "../../../context/AuthContext";
import UserSidebar from "./UserSidebar";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "Grievance Management",
  activeSubmenu: "",
};

const SideBar = () => {
  const { user } = useContext(AuthContext);
  const loginDetails = JSON.parse(localStorage.getItem("login_Details"));
  const usertype = localStorage.getItem("user_type");

  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const filteredSuperAdminMenuList = SuperAdminMenuList.filter((item) => {
    if (item?.title === "Client" && loginDetails?.user?.client_id > 0) {
      return false; // Exclude Client menu if client_id is greater than 0
    }
    if (
      item?.title === "users Category" &&
      loginDetails?.user?.client_id === 0
    ) {
      return false; // Exclude Users Category menu if client_id is 0
    }
    return true;
  });

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <ul className="metismenu" id="menu">
          {user?.role === "sadmin" && (
            <SuperAdminSidebar
              state={state}
              setState={setState}
              menuListData={filteredSuperAdminMenuList}
            />
          )}

          {user?.role === "admin" && (
            <AdminSidebar
              state={state}
              setState={setState}
              menuListData={AdminMenuList}
            />
          )}
          {user?.role === "3" && (
            <AdminSidebar
              state={state}
              setState={setState}
              menuListData={AdminMenuList}
            />
          )}

          {usertype === "user" && (
            <UserSidebar
              state={state}
              setState={setState}
              menuListData={UserMenuList}
            />
          )}
        </ul>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
